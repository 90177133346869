<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div class="layout-logo">
          <router-link to="/">
            <!-- <img class="logo" alt="Logo" :src="logo" /> -->
          </router-link>
        </div>

        <!-- <AppProfile :user="user" />
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" /> -->
        <AppProfile />
        <AppMenu :model="menuFilter" @menuitem-click="onMenuItemClick" />
      </div>
    </transition>

    <div class="layout-main">
      <div class="spinner-border text-primary" v-if="loadingData"></div>
      <router-view v-if="!loadingData" />
    </div>

    <!-- <AppConfig
      :layoutMode="layoutMode"
      :layoutColorMode="layoutColorMode"
      @layout-change="onLayoutChange"
      @layout-color-change="onLayoutColorChange"
    /> -->

    <AppFooter />
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppProfile from './AppProfile.vue';
import AppMenu from './AppMenu.vue';
// import AppConfig from "./AppConfig.vue";
import AppFooter from './AppFooter.vue';
// import axios from "axios";

export default {
  name: 'appcontent',
  data() {
    return {
      // user:{},

      loadingData: true,
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Dashboard',
          icon: 'pi pi-fw pi-home',
          meta: [
            'HQ Administrator',
            'Kerawang Administrator',
            'Branch Administrator',
            'Administrator',
            'Salesperson',
            'Technician',
            'Warehouse',
            'Receptionist',
          ],
          to: '/dashboard',
        },
        {
          label: 'Technician Tools',
          icon: 'pi pi-fw pi-clone',
          meta: [
            'HQ Administrator',
            'Kerawang Administrator',
            'Branch Administrator',
            'Administrator',
            'Technician',
            'Warehouse',
          ],
          items: [
            {
              label: 'My Customers',
              icon: 'pi pi-fw pi-user-edit',
              meta: [
                'HQ Administrator',
                'Kerawang Administrator',
                'Branch Administrator',
                'Technician',
                'Warehouse',
              ],
              to: '/mycustomers',
            },
            {
              label: 'Tracking',
              icon: 'pi pi-fw pi-compass',
              meta: [
                'HQ Administrator',
                'Kerawang Administrator',
                'Branch Administrator',
                'Administrator',
                'Technician',
                'Warehouse',
              ],
              to: '/tracking',
            },
          ],
        },
        {
          label: 'SystemQue',
          icon: 'pi pi-fw pi-clone',
          meta: [
            'HQ Administrator',
            'Kerawang Administrator',
            'Branch Administrator',
            'Administrator',
            'Salesperson',
            'Technician',
            'Receptionist',
          ],
          items: [
            {
              label: 'Frontliner',
              icon: 'pi pi-fw pi-user-edit',
              meta: [
                'HQ Administrator',
                'Kerawang Administrator',
                'Branch Administrator',
                'Receptionist',
                'Salesperson',
                'Technician',
              ],
              to: `/frontliner`,
            },
            {
              label: 'Receptionist',
              icon: 'pi pi-bell',
              meta: [
                'Kerawang Administrator',
                'Branch Administrator',
                'Administrator',
                'Receptionist',
              ],
              to: '/receptionist',
            },
            {
              label: 'Queue',
              icon: 'pi pi-bars',
              meta: [
                'Kerawang Administrator',
                'Branch Administrator',
                'Administrator',
                'Receptionist',
              ],
              to: '/queue',
            },
            {
              label: 'Statistics',
              icon: 'pi pi-bars',
              meta: [
                'HQ Administrator',
                'Kerawang Administrator',
                'Branch Administrator',
                'Administrator',
                'Salesperson',
                'Technician',
              ],
              to: '/statistics',
            },
          ],
        },
        {
          label: 'Administration',
          icon: 'pi pi-fw pi-clone',
          meta: [
            'HQ Administrator',
            'Kerawang Administrator',
            'Branch Administrator',
            'Administrator',
          ],
          items: [
            {
              label: 'My Staffs',
              icon: 'pi pi-fw pi-user-edit',
              meta: [
                'HQ Administrator',
                'Kerawang Administrator',
                'Branch Administrator',
                'Administrator',
              ],
              to: '/mystaffs',
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
    isLoggedIn: function(val) {
      if (val) {
        this.loadingData = true;
        this.dispatchGetStaff();
      }
    },
  },

  mounted() {
    // console.log(user);
    this.dispatchGetStaff();
  },
  methods: {
    dispatchGetStaff() {
      var user = {
        id: parseInt(localStorage.getItem('userId')) || null,
      };
      if (this.isLoggedIn) {
        this.$store.dispatch('logged/getStaff', user).then(() => {
          this.loadingData = false;
        });
      } else {
        this.loadingData = false;
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi',
          ),
          ' ',
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    staff() {
      let staff = this.$store.getters['logged/getStaff'];
      return staff.data.result;
    },
    menuFilter() {
      function isFilter(x) {
        return x.meta.includes(localStorage.getItem('staffPosition'));
      }
      var filteredMenu = this.menu.filter(isFilter);
      for (var i = 0; i < filteredMenu.length; i++) {
        if (filteredMenu[i].items) {
          if (filteredMenu[i].items.length > 0) {
            filteredMenu[i].items = filteredMenu[i].items.filter(isFilter);
          }
        }
      }
      return filteredMenu;
    },
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active':
            this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        'layout-sidebar',
        {
          'layout-sidebar-dark': this.layoutColorMode === 'dark',
          'layout-sidebar-light': this.layoutColorMode === 'light',
        },
      ];
    },
    logo() {
      return this.layoutColorMode === 'dark'
        ? 'assets/layout/images/logo_imika.png'
        : 'assets/layout/images/logo.svg';
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
    // AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss" scoped>
@import './App.scss';

.logo {
  width: 50px;
  height: 50px;
}
</style>
