<template>
	<div class="layout-footer">
		<span class="footer-text" style="margin-right: 5px">Kerawang Technology</span>
		<!-- <img class="logo" src="assets/layout/images/logo_imika.png" alt="Kerawang.SystemTee.App" width="80"/> -->
		<!-- <span class="footer-text" style="margin-left: 5px">Theme and Layout</span> -->
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style lang="scss" scoped>
// .logo{
// 	height: 40px;
// 	width:50px;
// }
</style>